import React, { FC } from 'react';
import { Table } from 'semantic-ui-react';

interface RecordLookupTableProps {
  data: string[][];
  columns: string[];
}

const RecordLookupTable: FC<RecordLookupTableProps> = ({ data, columns }) => (
  <Table compact striped>
    <Table.Header>
      <Table.Row>
        {columns.map((cell) => (
          <Table.HeaderCell>{cell}</Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {data.map((row) => (
        <Table.Row key={row[0] + row[1]}>
          {row.map((cell) => (
            <Table.Cell>{cell}</Table.Cell>
          ))}
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

export default RecordLookupTable;
