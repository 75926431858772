import React, { FC, useState } from 'react';
import { Button, Form, Header, Icon, Input, Message } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { isEqual } from 'lodash';

import CountrySelect from '@/components/CountrySelect';
import { leoOptionsDataObject } from '@/API';
import VotingStateAndJurisdictionSelect from '../VotingStateAndJurisdictionSelect';
import { RecordLookupQuery } from './RecordLookup';
import ColumnsSelect from '../ColumnsSelect';

const year = new Date().getUTCFullYear();
const startDateUtc = new Date(`${year}-01-01`);
startDateUtc.setTime(
  startDateUtc.getTime() + startDateUtc.getTimezoneOffset() * 60 * 1000,
);

const INITIAL_FORM_STATE = {
  startDate: startDateUtc,
  endDate: new Date(),
  nbId: '',
  email: '',
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: null,
  countries: [],
  votingStates: [],
  leoJurisdictions: [],
  columnsSelector: 'ALL',
};

interface RecordLookupControlsProps {
  leoOptionsData: leoOptionsDataObject[];
  onQuery: (data: RecordLookupQuery) => void;
  isLoading: boolean;
  link: string;
  setLink: React.Dispatch<React.SetStateAction<string>>;
  downloadFilename: string;
}

const RecordLookupControls: FC<RecordLookupControlsProps> = ({
  leoOptionsData,
  onQuery,
  isLoading,
  link,
  setLink,
  downloadFilename,
}) => {
  const [errorMsg] = useState<string | null>(null);

  const [startDate, setStartDate] = useState(INITIAL_FORM_STATE.startDate);
  const [endDate, setEndDate] = useState(INITIAL_FORM_STATE.endDate);

  const [nbId, setNbId] = useState(INITIAL_FORM_STATE.nbId);
  const [email, setEmail] = useState(INITIAL_FORM_STATE.email);
  const [firstName, setFirstName] = useState(INITIAL_FORM_STATE.firstName);
  const [middleName, setMiddleName] = useState(INITIAL_FORM_STATE.middleName);
  const [lastName, setLastName] = useState(INITIAL_FORM_STATE.lastName);
  const [dateOfBirth, setDateOfBirth] = useState(
    INITIAL_FORM_STATE.dateOfBirth,
  );

  const [countries, setCountries] = useState<string[]>(
    INITIAL_FORM_STATE.countries,
  );
  const [votingStates, setVotingStates] = useState<string[]>(
    INITIAL_FORM_STATE.votingStates,
  );
  const [leoJurisdictions, setLeoJurisdictions] = useState<string[]>(
    INITIAL_FORM_STATE.leoJurisdictions,
  );
  const [columnsSelector, setColumnsSelector] = React.useState(null);
  const [columnsToggled, setColumnsToggled] = React.useState<string[]>(null);

  const resetForm = () => {
    setStartDate(INITIAL_FORM_STATE.startDate);
    setEndDate(INITIAL_FORM_STATE.endDate);
    setCountries(INITIAL_FORM_STATE.countries);
    setVotingStates(INITIAL_FORM_STATE.votingStates);
    setLeoJurisdictions(INITIAL_FORM_STATE.leoJurisdictions);
    setNbId(INITIAL_FORM_STATE.nbId);
    setEmail(INITIAL_FORM_STATE.email);
    setFirstName(INITIAL_FORM_STATE.firstName);
    setMiddleName(INITIAL_FORM_STATE.middleName);
    setLastName(INITIAL_FORM_STATE.lastName);
    setDateOfBirth(INITIAL_FORM_STATE.dateOfBirth);
  };

  const formHasChanges = !isEqual(INITIAL_FORM_STATE, {
    startDate,
    endDate,
    nbId,
    email,
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    countries,
    votingStates,
    leoJurisdictions,
  });

  const collateQueryData = (): RecordLookupQuery => ({
    startDate,
    endDate,
    countries,
    votingStates,
    leoJurisdictions,
    nbId,
    email,
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    columnsSelector,
    columnsToggled,
  });

  return (
    <Form>
      <Header as="h2">VFA Record Lookup</Header>
      <Message warning icon visible>
        <Icon name="warning sign" />
        <Message.Content>
          This will return live voter data including confidential personally
          identifiable information (PII). Full data available for the last
          twelve months only. Use with caution!
        </Message.Content>
      </Message>
      <Message>
        <Message.Content>
          Text fields are case insensitive and support % as a wildcard token
          (e.g., dav% matches David and Dave).
        </Message.Content>
      </Message>
      <Form.Field>
        <label>Start date</label>
        <SemanticDatepicker
          value={startDate}
          onChange={(_evt, data) => setStartDate(data.value as Date)}
        />
      </Form.Field>
      <Form.Field>
        <label>End date</label>
        <SemanticDatepicker
          value={endDate}
          onChange={(_evt, data) => setEndDate(data.value as Date)}
        />
      </Form.Field>
      <Form.Field>
        <label>NationBuilder ID</label>
        <Input value={nbId} onChange={(_evt, data) => setNbId(data.value)} />
      </Form.Field>
      <Form.Field>
        <label>Email</label>
        <Input value={email} onChange={(_evt, data) => setEmail(data.value)} />
      </Form.Field>
      <Form.Field>
        <label>First Name</label>
        <Input
          value={firstName}
          onChange={(_evt, data) => setFirstName(data.value)}
        />
      </Form.Field>
      <Form.Field>
        <label>Middle Name</label>
        <Input
          value={middleName}
          onChange={(_evt, data) => setMiddleName(data.value)}
        />
      </Form.Field>
      <Form.Field>
        <label>Last Name</label>
        <Input
          value={lastName}
          onChange={(_evt, data) => setLastName(data.value)}
        />
      </Form.Field>
      <Form.Field>
        <label>Date of Birth</label>
        <SemanticDatepicker
          value={dateOfBirth}
          onChange={(_evt, data) => setDateOfBirth(data.value as Date)}
        />
      </Form.Field>
      <Form.Field>
        <label>Country</label>
        <CountrySelect
          multiple
          search
          value={countries}
          selection
          onChange={(_evt, data) => setCountries(data.value)}
        />
      </Form.Field>

      <VotingStateAndJurisdictionSelect
        leoOptionsData={leoOptionsData}
        setVotingStates={setVotingStates}
        votingStates={votingStates}
        setLeoJurisdictions={setLeoJurisdictions}
        leoJurisdictions={leoJurisdictions}
      />

      <ColumnsSelect
        targetQuery="full"
        radioValue={columnsSelector}
        setRadioValue={setColumnsSelector}
        columnsValue={columnsToggled}
        setColumnsValue={setColumnsToggled}
      />
      <>
        <Form.Field>
          <Button
            primary
            content="Search"
            disabled={isLoading}
            loading={isLoading}
            onClick={() => onQuery(collateQueryData())}
          />
        </Form.Field>
        {link && (
          <Button
            primary
            icon="download"
            labelPosition="right"
            content="Download"
            download={downloadFilename}
            as="a"
            href={link}
            onClick={() => setLink(null)}
          />
        )}
      </>
      {formHasChanges && (
        <Form.Field>
          <Button onClick={resetForm}>Reset form</Button>
        </Form.Field>
      )}
      {errorMsg && <Message color="red">{errorMsg}</Message>}
    </Form>
  );
};

export default RecordLookupControls;
