import React, { FC } from 'react';

import RecordLookup from '@/components/RecordLookup/RecordLookup';
import { leoOptionsDataObject } from '../../API';

interface RecordLookupTabProps {
  leoOptionsData: leoOptionsDataObject[];
}

const RecordLookupTab: FC<RecordLookupTabProps> = ({ leoOptionsData }) => {
  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      document.title = 'VFA Dashboard (Record Lookup)';
    }
  }, []);

  return <RecordLookup leoOptionsData={leoOptionsData} />;
};

export default RecordLookupTab;
