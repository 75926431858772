import React, { FC, useState, useContext } from 'react';
import { Grid, Message, Segment } from 'semantic-ui-react';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

import UserContext from '@/components/UserContext';
import DbContext from '@/components/DbContext';

import { leoOptionsDataObject } from '@/API';
import { getBackendConfig } from '@/lib/lambda';
import { callPaginatedPostApi } from '@/lib/paginated';
import extractDateString from '@/lib/extractDateString';
import { DateTime } from 'luxon';
import RecordLookupControls from './RecordLookupControls';
import RecordLookupTable from './RecordLookupTable';

interface RecordLookupProps {
  leoOptionsData: leoOptionsDataObject[];
}

export interface RecordLookupQuery {
  startDate: Date | null;
  endDate: Date | null;
  countries: string[];
  votingStates: string[];
  leoJurisdictions: string[];
  nbId: string;
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: Date | null;
  columnsSelector: string;
  columnsToggled: string[];
}

const RecordLookup: FC<RecordLookupProps> = ({ leoOptionsData }) => {
  const userContext = useContext(UserContext);
  const dbContext = useContext(DbContext);

  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [results, setResults] = useState(null);
  const [columnsList, setColumnsList] = useState(null);
  const [link, setLink] = useState(null);
  const [downloadFilename, setDownloadFilename] = useState(null);

  const initStartQuery = async ({
    startDate,
    endDate,
    countries,
    votingStates,
    leoJurisdictions,
    nbId,
    email,
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    columnsSelector,
    columnsToggled,
  }: RecordLookupQuery) => {
    if (isSending) return;
    setIsSending(true);
    setIsLoading(true);
    setErrorMsg(null);
    try {
      const data = {
        startDate: extractDateString(startDate),
        endDate: extractDateString(endDate),
        votingStates: votingStates.length > 0 ? votingStates : undefined,
        leoJurisdictions:
          leoJurisdictions.length > 0 ? leoJurisdictions : undefined,
        countries: countries.length > 0 ? countries : undefined,
        nbId,
        email,
        firstName,
        middleName,
        lastName,
        dateOfBirth: extractDateString(dateOfBirth),
        selectColumns: columnsSelector === 'ALL' ? undefined : columnsToggled,
      };
      const config = getBackendConfig(
        userContext?.signInUserSession?.idToken.jwtToken,
      );
      config.headers.Accept = 'application/json';
      let result = null as any;
      try {
        result = (await callPaginatedPostApi(
          dbContext.db,
          `data-csv`,
          data,
          config,
        )) as any;
      } catch {
        alert(
          "API Error. Maybe your login has expired? Try refreshing the page.  If that doesn't work, try splitting your download into smaller date ranges.  Email hpiwowar@gmail.com to report this error or for additional help.",
        );
        console.log('API error');
      }
      if (result?.count === undefined) {
        alert(
          "API Error. Maybe your login has expired? Try refreshing the page.  If that doesn't work, try splitting your download into smaller date ranges.  Email hpiwowar@gmail.com to report this error or for additional help.",
        );
        throw new Error('bad API response');
      }
      const csvContents = result?.csvResults;
      const internalHrefToCsv = URL.createObjectURL(
        new Blob([csvContents], {
          type: 'text/csv;charset=utf-8;',
        }),
      );
      setIsSending(false);
      const filenameTimestamp = DateTime.now().toFormat('yyyy-LL-dd-hh-mm-ss');
      setDownloadFilename(`${filenameTimestamp}-VFA-Record-Search.csv`);
      setIsLoading(false);
      setLink(internalHrefToCsv); // this makes the Download button appear
      const resultsData = result?.results;
      setResults(resultsData);
      const resultColumns = result?.columns;
      setColumnsList(resultColumns);

      console.log(result);
      setIsSending(false);
      setIsLoading(false);
    } catch (error) {
      setIsSending(false);
      setIsLoading(false);
      console.log('query failed ->', error);
      if (error.errors?.length > 0) {
        setErrorMsg(error.errors[0].message);
      }
    }
  };

  return (
    <Grid stackable>
      <Grid.Column width={4}>
        <RecordLookupControls
          leoOptionsData={leoOptionsData}
          isLoading={isLoading}
          onQuery={initStartQuery}
          link={link}
          setLink={setLink}
          downloadFilename={downloadFilename}
        />
        {errorMsg && <Message color="red">{errorMsg}</Message>}
      </Grid.Column>
      {results && columnsList && (
        <Grid.Column width={12}>
          <Segment style={{ maxHeight: '800px', overflowY: 'scroll' }}>
            <Grid>
              <Grid.Row columns="equal">
                <Grid.Column>
                  <h3>Record search results</h3>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <RecordLookupTable data={results} columns={columnsList} />
          </Segment>
        </Grid.Column>
      )}
    </Grid>
  );
};
export default RecordLookup;
