import React, { useContext, useState } from 'react';
import { Link, PageProps } from 'gatsby';
import { Tab } from 'semantic-ui-react';

import Layout from '@/components/Layout';
import UserContext from '@/components/UserContext';
import DbContext, { DbSwitcher } from '@/components/DbContext';
import { getBackendConfig } from '@/lib/lambda';
import { callPaginatedPostApi, callGetApi } from '@/lib/paginated';
import ExportTab from './index/ExportTab';
import TimeseriesTab from './index/TimeseriesTab';
import DebugTab from './index/DebugTab';
import RecordLookupTab from './index/RecordLookupTab';

const Home: React.FC<PageProps> = () => {
  const userContext = useContext(UserContext);
  const dbContext = useContext(DbContext);
  const [lastRefreshDate, setLastRefreshDate] = useState(null);
  const [leoOptionsData, setLeoOptionsData] = useState(null);

  const preFetchData = async () => {
    setLeoOptionsData(null);

    // Get LEO Jurisdictions from db
    try {
      const data = {};
      const config = getBackendConfig(
        userContext?.signInUserSession?.idToken.jwtToken,
      );
      let result = null as any;

      try {
        result = (await callPaginatedPostApi(
          dbContext.db,
          `get-leo-filter`,
          data,
          config,
        )) as any;
      } catch {
        alert(
          "API Error. Maybe your login has expired? Try refreshing the page.  If that doesn't work, try splitting your download into smaller date ranges.  Email hpiwowar@gmail.com to report this error or for additional help.",
        );
        console.log('API error');
      }

      setLeoOptionsData(
        result.results.map((row) => ({
          state: row.state,
          leoJurisdiction: row.leo_jurisdiction,
        })),
      );
    } catch (error) {
      console.log('query failed ->', error);
    }

    // Get last refresh time from db
    try {
      const data = {};
      const config = getBackendConfig(
        userContext?.signInUserSession?.idToken.jwtToken,
      );
      let result = null as any;

      try {
        result = (await callGetApi(
          dbContext.db,
          `data-freshness`,
          data,
          config,
        )) as any;
      } catch {
        alert(
          "API Error. Maybe your login has expired? Try refreshing the page.  If that doesn't work, try splitting your download into smaller date ranges.  Email hpiwowar@gmail.com to report this error or for additional help.",
        );
        console.log('API error');
      }

      setLastRefreshDate(result.last_refresh_time);
    } catch (error) {
      console.log('query failed ->', error);
    }
  };

  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      document.title = 'VFA Dashboard';
    }
    if (userContext) {
      preFetchData();
    }
  }, [userContext]);

  const groups: string[] =
    userContext?.signInUserSession?.accessToken?.payload?.['cognito:groups'] ??
    [];

  const panes = [
    {
      menuItem: 'Export',
      render: () => (
        <Tab.Pane style={{ border: 'none' }}>
          <ExportTab
            leoOptionsData={leoOptionsData}
            lastRefreshDate={lastRefreshDate}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Timeseries',
      render: () => (
        <Tab.Pane style={{ border: 'none' }}>
          <TimeseriesTab />
        </Tab.Pane>
      ),
    },
  ];
  if (groups.includes('AwsDebug')) {
    panes.push({
      menuItem: 'AWS Debug Info',
      render: () => (
        <Tab.Pane style={{ border: 'none' }}>
          <DebugTab />
        </Tab.Pane>
      ),
    });
  }
  if (groups.includes('Admins')) {
    panes.push({
      menuItem: 'Record Lookup',
      render: () => (
        <Tab.Pane style={{ border: 'none' }}>
          <RecordLookupTab leoOptionsData={leoOptionsData} />
        </Tab.Pane>
      ),
    });
  }

  return (
    <Layout>
      {!userContext && (
        <>
          <p>Welcome to the VFA Dashboard!</p>
          <p>
            Please <Link to="/login">Log in</Link> to get started
          </p>
        </>
      )}
      {userContext && groups.includes('AwsDebug') && <DbSwitcher />}
      {userContext && <Tab panes={panes} />}
    </Layout>
  );
};

export default Home;
